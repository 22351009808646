<template>
  <v-dialog v-model="modal" @click:outside="$emit('close')" width="500">
  <v-card class="wallet-popup">
    <p class="heading mb-5">Transaction Details</p>
    <div class="transaction-details ">
      <div class="tw-flex">
        <v-col cols="4" class="question">Reference ID :</v-col>
        <v-col cols="8" class="answer text-uppercase">{{ transaction.transactionReference }}</v-col>
      </div>
      <div class="tw-flex">
        <v-col cols="4" class="question">Sender :</v-col>
        <v-col cols="8" class="answer">RoadPadi</v-col>
      </div>  <div class="tw-flex">
      <v-col cols="4" class="question">Amount:</v-col>
      <v-col cols="8" class="answer">₦{{transaction.amount}}</v-col>
    </div>
      <div class="tw-flex">
      <v-col cols="4" class="question">Description :</v-col>
      <v-col cols="8" class="answer" >{{transaction.description}} </v-col>
    </div>
      <div class="tw-flex">
        <v-col cols="4" class="question">Date : </v-col>
        <v-col cols="8" class="answer">{{ getDateTime(transaction.transactionDate)}}</v-col>
      </div>
    </div>
  </v-card>

  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import dayjs from "dayjs";

export default {
  name: "ViewTransaction",
  props:{
    modal: {
      type: [Boolean],
      default: true,
    },
  },
  computed:{
    ...mapGetters('walletStore', ['transaction'])
  },
  methods:{
    getDateTime(date){
      return dayjs(date).format("LLL")
    },
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>

.wallet-popup{
  padding: 50px;
}
.wallet-popup .heading{
  font-family: var(--fontInter);
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--black);

}
.transaction-details .question, .transaction-details .answer{
  font-family: var(--fontInter);
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: var(--black)
}
.transaction-details .answer{
  font-weight: 600;
}
</style>