<template>
  <div class="tw-w-full">
    <loader :loading="loading" v-if="loading"/>
    <div class="dashboard" v-else style="background: #F6F7F9">
      <div  class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between action-card" >
        <v-col lg="3" class="tw-mb-10 lg:tw-mb-0" >
          <v-card class="wallet-balance tw-w-full" flat>
            <icons name="open-wallet" color="#004AAD"/>
            <div class="mt-2 space">
              <span class="subtext">Your wallet balance is</span>
              <p class="amount mt-1">₦{{formatWithCommas(walletData.balance)}}</p>
            </div>
          </v-card>
        </v-col>
        <v-col lg="3" class="tw-mb-10 lg:tw-mb-0">
          <v-card  class="spending tw-w-full" flat >
            <span class="font-size-18">Spendings</span>
            <div class="tw-flex mt-5">
              <v-col cols="3">
                <icons name="wallet-with-card" color="#004AAD" class="mt-2"/>

              </v-col>
              <v-col cols="9" class="ml-2 space">
                <p class="font-size-28 mt-2 mb-1">₦{{formatWithCommas(monthlySpending)}}</p>
                <p class="subtext">Spent this month</p>
              </v-col>
            </div>
          </v-card>
        </v-col>
        <v-col lg="2" class="">
          <v-card class="fund-wallet tw-w-full" flat  @click="handleCloseModal">
            <icons name="closed-wallet" color="#004AAD" class="mt-2"/>
            <p class="subheading mt-2"> Fund Wallet</p>
          </v-card>
<!--          <v-card class="withdraw tw-w-full tw-mt-5" flat  @click="handleWithdrawalModal">-->
<!--            <img src="@/assets/wallet-icons/withdraw.svg" alt="Add a booking icon" class="mt-2"/>-->
<!--            <p class="subheading mt-2">Withdraw from Wallet</p>-->
<!--          </v-card>-->
        </v-col>
        <v-col lg="3" class="tw-flex tw-items-end tw-justify-end">
<!--          <v-card class="account-number tw-flex tw-w-full tw-mt-5" flat>-->
<!--            <v-col cols="5">-->
<!--              <img src="@/assets/wallet-icons/bank.svg" alt="Bank icon" class="mt-2"/>-->
<!--              <p class="subheading mt-2 mb-1">Account Number</p>-->
<!--            </v-col>-->
<!--            <div class="middle-line"></div>-->
<!--            <v-col cols="7" class="ml-2 display-center-with-flex">-->
<!--              <p class="digits">{{walletData.globusAccount.accountNumber}}</p>-->
<!--            </v-col>-->

<!--          </v-card>-->
        </v-col>
      </div>
      <div class="scrollable">
        <div class="transaction-history mt-6">
          <h5 class="heading font-size-18">Transactions</h5>
          <div class="grouped-cards mt-5 tw-flex-wrap tw-flex tw-gap-5">
            <v-card  flat  v-for="(transaction, index) in walletData.transactions" :key="index" @click="handleClick(transaction)">
              <div class="topmost tw-flex tw-capitalize">
                <span :style="{color : '#004AAD'}" class="credit" v-if="transaction.paymentType === 'CREDIT'">Credit</span>
                <span class="debit" v-if="transaction.paymentType === 'DEBIT'">Debit</span>
                <v-spacer></v-spacer>
                <icons name="download"/>
              </div>
              <div class="middle-layer my-4 tw-flex">
                <p class="question"> Amount : <span class="answer">₦{{formatWithCommas(transaction.amount)}}</span></p>
                <v-spacer></v-spacer>
                <p class="question"> Reference ID : <span class="question text-uppercase">{{ transaction.reference }} </span></p>
              </div>
              <div class="second-middle-layer my-4 tw-flex">
                <p class="question"> Desc : <span class="question">{{transaction.description}}</span></p>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="bottom-layer tw-flex tw-justify-end">
                <span class="date"> {{ getDateTime(transaction.createdAt) }}</span>
              </div>
            </v-card>
            <view-transaction :modal="dialog" @close="closeModal"/>
          </div>
        </div>
      </div>
    </div>

    <deposit-modal  :show-modal="showModal"
                    header="Enter amount to deposit" width="500px"
                    color="#004AAD" @closeModal="handleCloseModal" @action="handleShowPaymentModal" />
    <withdrawal-modal color="#004AAD" :dialog="withdrawalModal" @closeModal="handleWithdrawalModal"
                      :wallet-balance="walletData.balance" :loading="withdrawalLoading" @action="handleWithdrawal"/>
    <paystack
        :amount="depositAmount*getKobo"
        :email="developerData.email"
        :paystackkey="getPayStackKey"
        :reference="reference"
        :callback="handleVerifyPaysackPayment"
        :close="handleClosePaytackModal"
        :embed="false"
        :channels= "['card','bank_transfer']"
        :subaccount="walletData.paystackSubAccountCode"
    >
      <div class="tw-hidden" ref="paystack">Pay</div>
    </paystack>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import ViewTransaction from "@/components/reuseables/ViewTransaction.vue";
import Loader from "@/components/reuseables/Loader.vue";
import paystack from 'vue-paystack';
import {
  createPaymentLog,
  getWalletByUserEmail,
  verifyPaystackPayment, withdrawFromWallet
} from "@/services/api/APIService";

import depositModal from "@/components/reuseables/DepositModal.vue";
import dayjs from "dayjs";
import WithdrawalModal from "@/components/reuseables/WithdrawalModal.vue";
import Icons from "@/components/reuseables/Icons.vue";
export default {
  name: "WalletDashboard",
  components: {Icons, WithdrawalModal, depositModal, Loader, ViewTransaction, paystack},
  data(){
    return{
      dialog: false,
      loading: false,
      walletData:{},
      showPaymentModal: false,
      paymentUrl : "",
      showModal : false,
      depositAmount : 0,
      paymentLoading : false,
      paymentReference: "",
      withdrawalModal: false,
      withdrawalLoading : false,
      monthlySpending: 0,
      reference:null
    }
  },
  computed:{
    ...mapGetters("developerData", ["developerData"]),
    getPayStackKey(){
      return process.env.VUE_APP_PAYSTACK_LIVE_PUBLIC_KEY
    },
    getKobo(){
      return process.env.VUE_APP_KOBO
    }
  },
  methods:{
    getReference(){
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for( let i=0; i < 10; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      this.reference = text;
    },
    handleWithdrawalModal(){
      this.withdrawalModal = !this.withdrawalModal
    },
    handleCloseModal(){
      this.showModal = !this.showModal
    },
    async createFundWalletPaymentLog(depositAmount){
      let userData = JSON.parse(sessionStorage.getItem("userData"))
      this.getReference()
      let paymentLog = {}
      paymentLog.amount = depositAmount
      paymentLog.currency="NGN"
      paymentLog.reference= this.reference
      paymentLog.service = "Npay"
      paymentLog.paymentProcessor = "paystack";
      paymentLog.purpose = "Fund Developer Wallet";
      paymentLog.userEmail = userData.email;
      await  createPaymentLog(paymentLog).then(() =>{
        this.$refs.paystack.click()
        console.log("Done With creating Payment Log")
      })
    },

    getMonthlySpending(transactions){
      this.monthlySpending = 0
      if (transactions) {
        transactions.forEach(transaction => {
          if (dayjs(transaction.createdAt).month() === dayjs().month() && transaction.transactionType === 'DEBIT') {
            this.monthlySpending += transaction.amount
          }
        })
      }
    },

    async handleShowPaymentModal(depositAmount){
      if (!depositAmount){
        this.$displaySnackbar({
          message: "Deposit Amount cannot be empty",
          success: false,
        });
      }
      else {
        this.handleCloseModal()
        this.depositAmount = depositAmount
        await this.createFundWalletPaymentLog(depositAmount)
      }
    },

    handleWithdrawal(withdrawalAmount){
      if (!withdrawalAmount){
        this.$displaySnackbar({
          message: "Withdrawal Amount cannot be empty",
          success: false,
        });
      }
      else if(Number.parseFloat(withdrawalAmount) > Number.parseFloat(this.walletData.balance)){
        this.$displaySnackbar({
          message: "Withdrawal amount exceeded your wallet balance",
          success: false,
        });
      }
      else if (Number.parseFloat(this.walletData.balance) - withdrawalAmount <= 50000){
        this.$displaySnackbar({
          message: "Insufficient fund, by withdrawing "+withdrawalAmount+ " you exceeded minimum wallet balance of N50,000.",
          success: false,
        });
      }
      else {
        this.withdrawalLoading = true
        let  data = {}
        data.email = this.developerData.email
        data.amount = withdrawalAmount *100
        data.beneficiaryAccount = this.developerData.accountDetail.accountNumber
        data.beneficiaryName = this.developerData.accountDetail.accountName
        data.narration = "Withdrawal to company account"
        data.beneficiaryBankCode= this.developerData.accountDetail.bankCode
        withdrawFromWallet(data).then(res => {
          this.withdrawalLoading = false
          console.log(res.data)
          this.withdrawalModal = false
          this.getWallet()
        }).catch((err) => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
          this.withdrawalLoading = false
        });
        console.log(data,"data-----------")
      }
    },

    handleClosePaytackModal(){

    },

    handleVerifyPaysackPayment(){
      let data = {
        "paystackData":{
          "reference":this.reference
        }
      }
      verifyPaystackPayment(data).then(() => {
        this.getWallet()
      })
    },

    handleClick(transaction) {
      this.$store.dispatch('walletStore/selectedTransaction', transaction)
      this.dialog = true;
    },
    closeModal(){
      this.dialog = false
    },
    getDateTime(date){
      return dayjs(date).format("LLL")
    },
    formatWithCommas(value) {
      if (!value) return '0.0';
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      }).format(value);
    },

    async getWallet(){
      this.loading = true
      let userData = JSON.parse(sessionStorage.getItem("userData"))
      let data = {}
      data.userEmail = userData.email
      await getWalletByUserEmail(data).then(async res => {
        this.walletData = res.data.wallet
        this.getMonthlySpending(this.walletData.transactions)
        if(this.walletData.transactions) {
          this.walletData.transactions.sort(function (a, b) {
            return (
                dayjs(b.createdAt).toDate() -
                dayjs(a.createdAt ).toDate()
            );
          });
        }
      }).finally(()=> this.loading = false)
    },

  },
  async created() {
    await this.getWallet()
  }
}
</script>

<style scoped lang="scss">
.wallet-balance, .spending{
  height: 182px;
  background: var(--offWhite);
  box-shadow: 0px 4.03659px 40.3659px rgba(79, 79, 79, 0.02);
  border-radius: 15px;
  padding: 35px;
}
.subtext{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(79, 79, 79, 0.62);
}
.wallet-balance .amount{
  font-family: var(--fontInter);
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #001127;
}
.font-size-28{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #001127;

}
.font-size-18{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 17, 39, 0.75);
}
.withdraw, .fund-wallet, .account-number{
  height: 85px;
  background: var(--offWhite);
  box-shadow: 0 4.03659px 40.3659px rgba(79, 79, 79, 0.02);
  border-radius: 15px;
  padding: 10px 30px;

}
.subheading{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--brown);
}
.sculptor{
  display: flex;
  height:182px;
  //position: relative;
}
.account-number{
  display: flex;
  align-items: center;
  bottom: 0;
}
.middle-line{
  height: 80%;
  border: none;
  border-radius: 30px;
  border-left: 2.3px solid rgba(79, 79, 79, 0.17);
}
.digits{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 17, 39, 0.75);
}
.transaction-history{
  background: rgba(253, 255, 252, 0.06);
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 30px;
}

.transaction-history .v-card{
  box-sizing: border-box;
  width: 320px;
  min-height: 190px;
  padding: 20px 30px;
  background: var(--offWhite);
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04)!important;
}
.transaction-history .v-card:hover{
  border: 1px solid var(--offWhite);
}
.credit{
  font-family: var(--fontInter);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--primaryColor);
}
.debit{
  font-family: var(--fontInter);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #BA68C8;

}
.question, .date,.time{
  font-family: var(--fontInter);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #3F4D5D;
}
.question{
  height: 30px;
}
.answer{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--black);
}
.grouped-cards{
  min-height: 50vh;
  max-height: 50vh;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;

  @media screen and(max-width: 786px) {
    min-height: max-content;
    max-height: max-content;
  }
}

.grouped-cards::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 2px;
}
.grouped-cards::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}

</style>